import React, { useEffect, useState } from "react";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import { Column, Columns } from "../components/columns";
//@ts-ignore
import Fade from "react-reveal/Fade";
import heroBg from "../images/donor-voices/donor-voices_hero-bg.jpg";
import heroStudentShield from "../images/donor-voices/donor-voices_hero-student-shield.png";
import quotationIcon from "../images/icons/quotation-icon.svg";
import endQuotationIcon from "../images/icons/end-quotation-icon.png";
import voicesBg from "../images/donor-voices/donor-voices_voices-bg.png";
import messageBg from "../images/donor-voices/donor-voices_message-bg.png";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/donor-voices/donor-voices_wrapup-bg.jpg";
import mobileWrapup from "../images/donor-voices/donor-voices_wrapup-mobile.jpg";
import { Link } from "react-router-dom";
import shield from "../images/donor-voices/donor-voices_shield.png";
import curve from "../images/donor-voices/donor-voices_curve.png";
import { getAllDonorVoices } from "../cms/utilities";
import parse from "html-react-parser";

const DonorVoices = () => {
  let isDesktop = window.innerWidth >= 1024;

  interface Voice {
    attribution: string;
    quote: string;
    voiceType: string;
  }

  interface VoiceTypeArrays {
    alumni: Voice[];
    teacher: Voice[];
    donor: Voice[];
    student: Voice[];
    test: Voice[];
  }

  const [currentVoices, setCurrentVoices] = useState<VoiceTypeArrays>({
    alumni: [],
    teacher: [],
    donor: [],
    student: [],
    test: [],
  });
  //@ts-ignore
  useEffect(async () => {
    const voices = await getAllDonorVoices();

    let alumni: Voice[] = [];
    let teacher: Voice[] = [];
    let donor: Voice[] = [];
    let student: Voice[] = [];
    let test: Voice[] = [];

    voices.map((voice: any, id: any) => {
      let startQuoteAdded = voice.acf.quote.replace(`<p>`, `<p>“`);
      let closingTagIndex = startQuoteAdded.lastIndexOf("</p>");
      let quoteWithMarks =
        startQuoteAdded.substring(0, closingTagIndex) + `”</p>`;

      let voiceToAdd = {
        attribution: voice.acf.attribution,
        quote: quoteWithMarks,
        voiceType: voice.acf.voice_type,
      };

      if (voice.acf.voice_type === "Alumni") {
        alumni.push(voiceToAdd);
      } else if (voice.acf.voice_type === "Teacher") {
        teacher.push(voiceToAdd);
      } else if (voice.acf.voice_type === "Donor") {
        donor.push(voiceToAdd);
      } else if (voice.acf.voice_type === "Student") {
        student.push(voiceToAdd);
      } else {
        test.push(voiceToAdd);
      }
    });

    let currentState: VoiceTypeArrays = {
      alumni: alumni,
      teacher: teacher,
      donor: donor,
      student: student,
      test: test,
    };
    setCurrentVoices(currentState);
  }, []);

  return (
    <div className="container donor-voices">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="donor-voices-section_hero">
        <div className="hero-bg" style={{ backgroundImage: `url(${heroBg})` }}>
          <div className="padding">
            {isDesktop && <Header logoColor="white" />}
            <Fade bottom>
              <p className="subhead">Donor Voices</p>
              <h1 className="heading">
                Loyal, true, amid the crowd, our voices loudly raise.
              </h1>
            </Fade>
            <Fade bottom>
              <Columns>
                <Column className="left">
                  <div className="img-container">
                    <img className="student-shield" src={heroStudentShield} />
                  </div>
                </Column>
                <Column className="right">
                  <div className="testimonials">
                    <div className="testimonial-block">
                      <Fade bottom>
                        <div className="quote">
                          <img src={quotationIcon} className="quotation-mark" />
                          <p>
                            After attending Hoban for three years, I have fully
                            embraced becoming a Hoban Knight. With teachers who
                            push students to reach their fullest potential,
                            always going the extra mile to make sure they are
                            prepared to succeed, I am truly blessed to be here.
                            Both inside and outside of the classroom, I have
                            been surrounded by people who want the best for me
                            and care about my wellbeing.
                          </p>
                          <img
                            src={endQuotationIcon}
                            className="end-quotation-mark"
                          />
                        </div>
                        <div className="author">
                          <span className="name">Olivia Harwood ’23</span>
                          <span className="about"></span>
                        </div>
                      </Fade>
                    </div>
                  </div>
                </Column>
              </Columns>
            </Fade>
          </div>
        </div>
      </PageSection>
      <PageSection className="donor-voices-section_voices">
        <div className="voices-bg top" style={{ backgroundColor: "#fefdf5" }}>
          {isDesktop && <img className="corner-shield" src={shield} />}
          {isDesktop && <img className="corner-curve" src={curve} />}
          <div className="padding">
            <Fade bottom>
              <div className="voice-type-container">
                <div className="title">
                  <h2>Alumni Voices</h2>
                </div>
                {currentVoices &&
                  currentVoices.alumni &&
                  currentVoices.alumni.map((voice, id) => {
                    return (
                      <div key={id} className="voice-content">
                        <div className="quote">{parse(voice.quote)}</div>
                        <div className="author">
                          <span className="name">
                            <p>{voice.attribution}</p>
                          </span>
                          {/* <br /> */}
                          <span className="about"></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fade>
            <Fade bottom>
              <div className="voice-type-container bottom-pad">
                <div className="title">
                  <h2>Teacher Voices</h2>
                </div>
                {currentVoices &&
                  currentVoices.teacher &&
                  currentVoices.teacher.map((voice, id) => {
                    return (
                      <div key={id} className="voice-content">
                        <div className="quote">{parse(voice.quote)}</div>
                        <div className="author">
                          <span className="name">
                            <p>{voice.attribution}</p>
                          </span>
                          {/* <br /> */}
                          <span className="about"></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fade>
          </div>
        </div>
      </PageSection>
      <PageSection className="donor-voices-section_message">
        <div
          className="message-bg"
          style={{ backgroundImage: `url(${messageBg})` }}
        >
          <Fade bottom>
            <h1 className="message">Upon these rocks we stand as one.</h1>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="donor-voices-section_voices">
        <div className="voices-bg donors">
          <div className="padding">
            <Fade bottom>
              <div className="voice-type-container">
                <div className="title">
                  <h2>Donor Voices</h2>
                </div>
                {currentVoices &&
                  currentVoices.donor &&
                  currentVoices.donor.map((voice, id) => {
                    return (
                      <div key={id} className="voice-content">
                        <div className="quote">{parse(voice.quote)}</div>
                        <div className="author">
                          <span className="name">
                            <p>{voice.attribution}</p>
                          </span>
                          {/* <br /> */}
                          <span className="about"></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="voice-type-container">
                <div className="title">
                  <h2>Student Voices</h2>
                </div>
                {currentVoices &&
                  currentVoices.student &&
                  currentVoices.student.map((voice, id) => {
                    return (
                      <div key={id} className="voice-content">
                        <div className="quote">{parse(voice.quote)}</div>
                        <div className="author">
                          <span className="name">
                            <p>{voice.attribution}</p>
                          </span>
                          {/* <br /> */}
                          <span className="about"></span>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* <div className="voice-type-container">
                <div className="title">
                  <h2>Test Voices</h2>
                </div>

                {currentVoices &&
                  currentVoices.test &&
                  currentVoices.test.map((voice, id) => {
                    return (
                      <div key={id} className="voice-content">
                        <div className="quote">{parse(voice.quote)}</div>
                        <div className="author">
                          <span className="name">
                            <p>{voice.attribution}</p>
                          </span>
                          
                          <span className="about"></span>
                        </div>
                      </div>
                    );
                  })}
              </div> */}
            </Fade>
          </div>
        </div>
      </PageSection>
      <PageSection className="donor-voices-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to You</p>
            <p>
              Join us in strengthening the {!isDesktop && <br />}Hoban
              experience.
            </p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default DonorVoices;
