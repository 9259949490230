export const Columns = (props: any) =>  {
  return (
    <div className={`columns ${props.className || ''}`}>
      {props.children}
    </div>
  );
};

export const Column = (props: any) =>  {
  return (
    <div className={`column ${props.className || ''}`}>
      {props.children}
    </div>
  );
};