import BearCarousel, {
  TBearSlideItemDataList,
  BearSlideItem,
  elClassName,
} from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import image1 from "../images/home/image-carousel/image-1.jpg";
import image2 from "../images/home/image-carousel/image-2.jpg";
import image3 from "../images/home/image-carousel/image-3.jpg";
import image4 from "../images/home/image-carousel/image-4.jpg";
import nextArrow from "../images/icons/nextArrow.svg";
import prevArrow from "../images/icons/prevArrow.svg";

interface CarouselProps {
  image1Url?: string;
  image2Url?: string;
  image3Url?: string;
  image4Url?: string;
}

export const Bear = ({
  image1Url,
  image2Url,
  image3Url,
  image4Url,
}: CarouselProps) => {
  let isDesktop = window.innerWidth >= 1024;

  const images = [
    { id: 1, image: image1Url || image1 },
    { id: 2, image: image2Url || image2 },
    { id: 3, image: image3Url || image3 },
    // { id: 4, image: image4Url || image4 },
  ];

  if (image4Url) {
    images.push({ id: 4, image: image4Url || image4 });
  }

  const bearSlideItemData: TBearSlideItemDataList = images.map((row) => {
    return {
      key: row.id,
      children: <BearSlideItem imageUrl={row.image} />,
    };
  });

  return (
    <BearCarousel
      data={bearSlideItemData}
      isEnableLoop
      isEnableNavButton={isDesktop ? true : false}
      renderNavButton={(toPrev, toNext) => {
        return (
          <div className={elClassName.navGroup}>
            <button
              type="button"
              className={elClassName.navPrevButton}
              onClick={() => toPrev()}
            >
              <img src={prevArrow} />
            </button>
            <button
              type="button"
              className={elClassName.navNextButton}
              onClick={() => toNext()}
            >
              <img src={prevArrow} />
            </button>
          </div>
        );
      }}
      aspectRatio={{ widthRatio: 1440, heightRatio: 799 }}
    />
  );
};
