import { useState, useEffect } from "react";
import { getAuthor, getFeaturedImage } from "./utilities";
import parse from "html-react-parser";
import defaultImg from "./default.png";
import { Link } from "react-router-dom";
import rightArrow from "../images/icons/right-arrow.svg";
//@ts-ignore
import Fade from "react-reveal/Fade";

//@ts-ignore
export default function Post({ post, type }) {
  const [postImgAndAuthor, setPostImgAndAuthor] = useState({
    featImgUrl: "",
    author: "",
  });
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const author = getAuthor(post.author);
      const featuredImg = getFeaturedImage(post.featured_media);
      //   resolve the promises in getAuthor and getFeaturedImg async functions using Promise.all
      Promise.all([author, featuredImg]).then((res) => {
        setPostImgAndAuthor({
          author: res[0],
          featImgUrl: res[1],
        });
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  let featuredImage = postImgAndAuthor.featImgUrl
    ? postImgAndAuthor.featImgUrl
    : defaultImg;

  let postMarkup = <div className="post"></div>;

  if (type === "additional") {
    postMarkup = (
      <div className="post">
        <div
          className="img-box"
          style={{
            backgroundImage: `url(${featuredImage || ""})`,
          }}
        ></div>
        <div className="info">
          <h4 className="date">{new Date(post.date).toDateString()}</h4>
          <h1 className="title">{post.title.rendered}</h1>
          {/* <Link to={`/post/${post.id}`} className="cta-button">
            <span>Read Article</span>
            <img src={rightArrow} />
          </Link> */}
          <a href={`/post/${post.id}`} className="cta-button">
            <span>Read More</span>
            <img src={rightArrow} />
          </a>
        </div>
      </div>
    );
  } else {
    postMarkup = (
      <div className="post">
        <div
          className="img-box"
          style={{
            backgroundImage: `url(${featuredImage || ""})`,
          }}
        ></div>
        <h4 className="date">{new Date(post.date).toDateString()}</h4>
        <h1 className="title">{post.title.rendered}</h1>
        {/* <Link to={`/post/${post.id}`} className="cta-button">
          <span>Read Article</span>
          <img src={rightArrow} />
        </Link> */}
        <a href={`/post/${post.id}`} className="cta-button">
          <span>Read More</span>
          <img src={rightArrow} />
        </a>
      </div>
    );
  }

  return (
    // <Link
    //   to={`/post/${post.id}`}
    //   className={type === "featured" ? "featured-link" : "additional-link"}
    // >
    //   <Fade bottom>{postMarkup}</Fade>
    // </Link>
    <a
      href={`/post/${post.id}`}
      className={type === "featured" ? "featured-link" : "additional-link"}
    >
      <Fade bottom>{postMarkup}</Fade>
    </a>
  );
}
