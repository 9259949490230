import { useState, useEffect } from "react";
import { Donor, getAllDonorsNames, getDonorsPage } from "./../cms/utilities";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import heroBg from "../images/donate/donate_hero_bg.jpg";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/donors/donors_wrapup-bg.jpg";
import mobileWrapup from "../images/donors/donors_wrapup-mobile.jpg";
import halfwayMessageBg from "../images/donors/donors-halfway-message.jpg";

//@ts-ignore
import Fade from "react-reveal/Fade";
import { alphabet } from "../cms/constants";
import { Link } from "react-router-dom";

const Donors = () => {
  let isDesktop = window.innerWidth >= 1024;

  const [donors, setDonors] = useState<Donor[]>([]);
  const [numTopHalfLetters, setNumTopHalfLetters] = useState<number>(0);

  //@ts-ignore
  useEffect(async () => {
    const donorsPage = await getDonorsPage();
    let fullNames = donorsPage.acf.donors_list.split(",");
    let donorsNames: Donor[] = [];
    fullNames.map((name: string) => {
      let spaceIndex = name.indexOf(" ");
      let first = name.substring(0, spaceIndex);
      let last = name.substring(spaceIndex + 1);
      donorsNames.push({
        firstName: first,
        lastName: last,
        fullName: name,
      });
    });

    let numLetters = 0;
    alphabet.map((letter: string, key: any) => {
      let letterNames = donorsNames.filter((donor: Donor) => {
        // return donor.lastName.charAt(0).toUpperCase() === letter;
        if (donor.fullName) {
          let lastSpaceIndex = donor.fullName.lastIndexOf(" ");
          let lastWord = donor.fullName.substring(lastSpaceIndex + 1);
          return lastWord.charAt(0).toUpperCase() === letter;
        }
      });

      if (letterNames.length > 0) {
        numLetters++;
      }
    });
    let topHalfLetters = Math.ceil(numLetters / 2);

    if (donorsNames) {
      setDonors(donorsNames);
      setNumTopHalfLetters(topHalfLetters);
    }
  }, []);

  const generateLettersMarkup = () => {
    let lettersDone = 0;
    let lettersMarkup = <></>;

    alphabet.map((letter: string, index: any) => {
      let letterNames = donors.filter((donor: Donor) => {
        // return donor.lastName.charAt(0).toUpperCase() === letter;
        if (donor.fullName) {

          // Force 'Secret Creative Organization' to go under 'S'
          if (donor.fullName === "Secret Creative Organization") {
            console.log("Its Secret!");
            return "S" === letter;
          }

          let lastSpaceIndex = donor.fullName.lastIndexOf(" ");
          let lastWord = donor.fullName.substring(lastSpaceIndex + 1);
          return lastWord.charAt(0).toUpperCase() === letter;
        }
      });

      if (letterNames.length > 0) {
        letterNames.sort(function (a, b) {
          var textA = a.lastName.toUpperCase();
          var textB = b.lastName.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        if (lettersDone === numTopHalfLetters) {
          lettersMarkup = (
            <>
              {lettersMarkup}
              <div className="halfway_message">
                <div
                  className="message-bg"
                  style={{ backgroundImage: `url(${halfwayMessageBg})` }}
                >
                  <Fade bottom>
                    <h1 className="message">Every Knight. Every Day.</h1>
                  </Fade>
                </div>
              </div>
              <div className="letter-section" key={`${index}`}>
                <Fade bottom>
                  <h1 className="letter-heading">{letter}</h1>
                  <div className="name-list">
                    {letterNames.map((donor, indexx) => {
                      return (
                        <p className="donor-name" key={`${indexx}`}>
                          {`${donor.firstName} ${donor.lastName}`}
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>
            </>
          );
        } else {
          lettersMarkup = (
            <>
              {lettersMarkup}
              <div className="letter-section" key={`${index}`}>
                <Fade bottom>
                  <h1 className="letter-heading">{letter}</h1>
                  <div className="name-list">
                    {letterNames.map((donor, indexx) => {
                      return (
                        <p className="donor-name" key={`${indexx}`}>
                          {`${donor.firstName} ${donor.lastName}`}
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>
            </>
          );
        }

        lettersDone++;
      }
    });

    return lettersMarkup;
  };

  return (
    <div className="container donors">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="donors-section_intro">
        <div
          className="hero-bg"
          style={isDesktop ? { backgroundImage: `url(${heroBg})` } : {}}
        >
          {isDesktop && <Header logoColor="blue" />}
          <Fade bottom>
            <h1 className="heading">
              You Make Us <b>Better.</b>
            </h1>
            <p className="intro-text">
              {/* The donors listed below have made the Hoban experience better for all students,
              now and in the future. Their gifts will ensure Hoban can continue to provide a
              Holy Cross education to the next generation of leaders—regardless of their
              financial circumstances—with the competence to see and the courage to act.{" "}
              <strong>
                Are you ready to join them in leading Hoban confidently into the future?
              </strong> */}
              The following donors have answered the call and graciously contributed to Every Knight, Every Day. Their belief in the campaign pillars of Achievement, Community & Wellbeing and Access will help ensure the vibrancy of Hoban for generations to come.&nbsp;
              <strong>
                Are you ready to join them in leading Hoban confidently into the future?
              </strong>
            </p>
          </Fade>
        </div>
      </PageSection>
      <PageSection className="donors-section_names">
        {isDesktop && <p className="vertical-title">Donors</p>}
        {donors && generateLettersMarkup()}
      </PageSection>
      <PageSection className="donors-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to you</p>
            <p>Join us in strengthening the Hoban experience.</p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default Donors;
