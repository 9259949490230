import { useState } from "react";
import { useMailChimp, Status } from "./mailchimpHook";

const Subscribe = (props: any) => {
  const [email, setEmail] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const {subscribe, status, error, value} = useMailChimp('https://hoban.us14.list-manage.com/subscribe/post?u=32a164d34de96d9681b5cbf64&id=e2f239d6ed')

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

  const handleSubmit = () => {
    if (email && validEmail.test(email)) {
      subscribe({
        EMAIL: email,
        tags: "7267177"
      });
    } else {
      setEmailError(true);
    }
  };

  let markup =
    <div className={`subscribe ${props.className || ''}`}>
      <div className="sign-up-form">
        <input className='sub-input' type="email" placeholder='Email Address' value={email ?? ''} onChange={(e) => {setEmailError(false); setEmail(e.target.value);}} />
        <button className="sub-button" onClick={handleSubmit}>
          {/* splash text *//* Sign Up */}
          {/* main site text */}<span className="footer-submit">Submit</span>
        </button>
      </div>
      {emailError && <p className="email-error">Please enter a valid email address</p>}
    </div>;
  
  if (status === Status.error) {
    console.log("Error: " + error);
    markup =
      <div className={`subscribe ${props.className || ''}`}>
        <p className="submit-message">There was an error signing you up for updates.</p>
      </div>;
  } else if (status === Status.success) {
    console.log("Success");
    markup =
      <div className={`subscribe ${props.className || ''}`}>
        <div className="sign-up-form">
          <p className="submit-message">Thank you for signing up!</p>
        </div>
      </div>;
  }

  return markup;
};

export default Subscribe;
