//@ts-nocheck
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import {
  getAuthor,
  getBlogPostPage,
  getFeaturedImage,
  getNumberPosts,
} from "./utilities";
import { POSTS_API_URL } from "./constants";
import Post from "./Post";
import PageSection from "../components/pageContent";
import Header from "../components/header";
//@ts-ignore
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/single-post/post-page_wrapup-bg.jpg";
import mobileWrapup from "../images/single-post/post-page_wrapup-mobile.jpg";
import testimonialBg from "../images/single-post/post-page_testimonial-bg.jpg";
import quotationIcon from "../images/icons/quotation-icon.svg";
import { Bear } from "../components/bearCarousel";

const SinglePost = () => {
  let isDesktop = window.innerWidth >= 1024;

  interface BlogQuote {
    attribution: string;
    attributionSubtext: string;
    quote: string;
  }

  interface BlogPostPageContent {
    afterPostQuote: BlogQuote;
  }

  const [currentPost, setCurrentPost] = useState({});
  const [additionalPosts, setAdditionalPosts] = useState([]);
  const [quote, setQuote] = useState<BlogPostPageContent>({
    afterPostQuote: {
      attribution: "",
      attributionSubtext: "",
      quote: "",
    },
  });
  const { id } = useParams();

  useEffect(async () => {
    await getCurrentPostFromServer();

    const blogPost = await getBlogPostPage();

    let content: BlogPostPageContent = {
      afterPostQuote: {
        attribution: blogPost.acf.attribution,
        attributionSubtext: blogPost.acf.attribution_subtext,
        quote: blogPost.acf.quote,
      },
    };

    setQuote(content);
  }, []);

  const getCurrentPostFromServer = async () => {
    const res = await axios.get(`${POSTS_API_URL}/${id}`);
    const post = await res.data;
    const featuredImg = await getFeaturedImage(post.featured_media);
    const author = await getAuthor(post.author);
    const additional = await getNumberPosts(3);
    const postProps = {
      title: post.title.rendered,
      content: post.content.rendered,
      featuredImg,
      author,
      date: post.date,
      image1: post.acf.image_1,
      image2: post.acf.image_2,
      image3: post.acf.image_3,
      image4: post.acf.image_4,
    };
    setCurrentPost(postProps);
    setAdditionalPosts(additional);
  };

  if (currentPost.title == null) {
    return (
      <div className="container post-page">
        {!isDesktop && <Header logoColor="blue" />}
        <PageSection className="post-page-section_intro">
          {isDesktop && <Header logoColor="blue" />}
          <Fade bottom>
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          </Fade>
        </PageSection>
      </div>
    );
  }

  return (
    <div className="container post-page">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="post-page-section_intro">
        {isDesktop && <Header logoColor="blue" />}
        <Fade bottom>
          <div className="intro-content">
            <h1 className="title">{currentPost.title}</h1>
            <p className="date">{new Date(currentPost.date).toDateString()}</p>
          </div>
        </Fade>
      </PageSection>
      <PageSection className="post-page-section_body">
        <Fade bottom>
          <div className="post-content">{parse(`${currentPost.content}`)}</div>
        </Fade>
        {currentPost.image1 && (
          <div className="carousel post">
            <Bear
              image1Url={currentPost.image1}
              image2Url={currentPost.image2}
              image3Url={currentPost.image3}
              image4Url={currentPost.image4}
            />
          </div>
        )}
      </PageSection>
      {quote &&
        quote.afterPostQuote &&
        quote.afterPostQuote.attribution !== "" && (
          <PageSection className="post-page-section_testimonial">
            <div
              className="testimonial-bg"
              style={
                isDesktop ? { backgroundImage: `url(${testimonialBg})` } : {}
              }
            >
              <div className="testimonials">
                <div className="testimonial-block">
                  <Fade bottom>
                    <div className="quote">
                      <img src={quotationIcon} className="quotation-mark" />
                      <p>{quote.afterPostQuote.quote}</p>
                    </div>
                    <div className="author">
                      <span className="name">
                        {quote.afterPostQuote.attribution}&nbsp;
                      </span>
                      <span className="about">
                        {quote.afterPostQuote.attributionSubtext}
                      </span>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </PageSection>
        )}
      <PageSection className="post-page-section_additional-updates">
        <div className="uneven-columns">
          <div className="left">
            <p>Additional Updates</p>
          </div>
          <div className="right">
            {additionalPosts && (
              <div
                className={isDesktop ? "additional-posts" : "featured-posts"}
              >
                {additionalPosts.map((post, id) => {
                  return (
                    <div key={id} className="list-item">
                      <Post
                        post={post}
                        type={isDesktop ? "additional" : "featured"}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </PageSection>
      <PageSection className="post-page-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to You</p>
            <p>
              Join us in strengthening the {!isDesktop && <br />}Hoban
              experience.
            </p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default SinglePost;
