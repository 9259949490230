import React from "react";
//@ts-ignore
import * as jsonp from 'jsonp';

export const Status = {
	idle: 'IDLE',
	loading: 'LOADING',
	success: 'SUCCESS',	
	error: 'ERROR'
}

const toQueryString = (params: any) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
}

export function useMailChimp(url: string) {
  const [status, setStatus] = React.useState(Status.idle);
  const [error, setError] = React.useState<string | null>(null);
  const [value, setValue] = React.useState<string | null>(null);

  const subscribe = React.useCallback((data) => {
    const params = toQueryString(data);
    const ajaxURL = url.replace("/post?", "/post-json?");
    const newUrl = ajaxURL + "&" + params;

    setError(null);
    setStatus(Status.success);

    jsonp(newUrl, { param: "c" }, (err: any, data: any) => {
      if (err) {
        setStatus(Status.error);
        setError(err);
      } else if (data.result !== "success") {
        setStatus(Status.error);
        setError(data.msg);
      } else {
        setStatus(Status.success);
        setValue(data.msg);
      }
    });
  }, []);

  return { subscribe, status, error, value };
}
