import React, { useState } from "react";
import { Link } from "react-router-dom";
import whiteLogo from "../images/campaign_logo.svg";
import blueLogo from "../images/campaign_logo_blue.svg";
import hamburger from "../images/icons/hamburger.svg";
//@ts-ignore
import Fade from "react-reveal/Fade";

const Header = (props: { logoColor: string }) => {
  let logo = props.logoColor === "blue" ? blueLogo : whiteLogo;
  let isDesktop = window.innerWidth >= 1024;
  const [showMobileNav, toggleShowMobileNav] = useState(false);

  if (isDesktop) {
    return (
      <header className="header">
        <Fade bottom>
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
        </Fade>
        <Fade bottom>
          <div className="hover-links">
            <div className="nav-link-container">
              <Link to="/about" className="nav-link">
                <span>About the Campaign</span>
              </Link>
            </div>
            <div className="nav-link-container">
              <Link to="/campaign-updates" className="nav-link">
                <span>Campaign Updates</span>
              </Link>
            </div>
            { <div className="nav-link-container">
              <Link to="/donors" className="nav-link">
                <span>Donors</span>
              </Link>
            </div> }
            <div className="nav-link-container">
              <Link to="/donor-voices" className="nav-link">
                <span>Donor Voices</span>
              </Link>
            </div>
            <div className="nav-link-container">
              <Link to="/donate" className="nav-link">
                <span>Giving</span>
              </Link>
            </div>
            <div className="nav-link-container">
              <Link to="/contact" className="nav-link">
                <span>Contact</span>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade bottom>
          <div className="circle-container">
            <Link to="/donate" className="donate-button">
              <span>Donate</span>
              <span>Now</span>
            </Link>
          </div>
        </Fade>
      </header>
    );
  } else {
    return (
      <header className="header">
        <Fade bottom>
          <div
            className="ham"
            onClick={() => toggleShowMobileNav(!showMobileNav)}
          >
            <img src={hamburger} />
          </div>
          <div className="logo-container">
            <Link to="/" className="logo-link">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          {/* </Fade> */}
          <div className={`mobile-nav ${showMobileNav ? "" : "collapsed"}`}>
            {/* <div className="nav-link-container ham">
              <p>ham</p>
            </div> */}

            <div className="hover-links">
              <div className="nav-link-container">
                <Link to="/about" className="nav-link">
                  <span>About the Campaign</span>
                </Link>
              </div>
              <div className="nav-link-container">
                <Link to="/campaign-updates" className="nav-link">
                  <span>Campaign Updates</span>
                </Link>
              </div>
              { <div className="nav-link-container">
                <Link to="/donors" className="nav-link">
                  <span>Donors</span>
                </Link>
              </div> }
              <div className="nav-link-container">
                <Link to="/donor-voices" className="nav-link">
                  <span>Donor Voices</span>
                </Link>
              </div>
              <div className="nav-link-container">
                <Link to="/donate" className="nav-link">
                  <span>Giving</span>
                </Link>
              </div>
              {/* <div className='nav-link-container'>
        <Link to='/campaign-updates' className='nav-link'>
          Campaign Updates
        </Link>
      </div> */}
              {/* <div className='nav-link-container'>
        <Link to='/donors' className='nav-link'>
          Donors
        </Link>
      </div> */}
              <div className="nav-link-container">
                <Link to="/contact" className="nav-link">
                  <span>Contact</span>
                </Link>
              </div>
              {/* <Fade bottom> */}
              <div className="circle-container">
                <Link to="/donate" className="donate-button">
                  <span>Donate</span>
                  <span>Now</span>
                </Link>
              </div>
            </div>
            {/* </Fade> */}
          </div>
        </Fade>
      </header>
    );
  }
};

export default Header;
