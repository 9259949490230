// Local CMS
// export const POSTS_API_URL = "http://everyknightcms.local/wp-json/wp/v2/posts";
// export const AUTHORS_API_URL =
//   "http://everyknightcms.local/wp-json/wp/v2/users";
// export const MEDIA_API_URL = "http://everyknightcms.local/wp-json/wp/v2/media";
// export const DONOR_VOICES_API_URL =
//   "http://everyknightcms.local/wp-json/wp/v2/donor_voices";

// Prod CMS
export const POSTS_API_URL = "https://cms.everyknight.org/wp-json/wp/v2/posts";
export const AUTHORS_API_URL =
  "https://cms.everyknight.org/wp-json/wp/v2/users";
export const MEDIA_API_URL = "https://cms.everyknight.org/wp-json/wp/v2/media";
export const DONOR_VOICES_API_URL =
  "https://cms.everyknight.org/wp-json/wp/v2/donor_voices";
export const DONORS_API_URL =
  "https://cms.everyknight.org/wp-json/wp/v2/donors";
export const PAGES_API_URL = "https://cms.everyknight.org/wp-json/wp/v2/pages";

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
