//@ts-nocheck
import { useState, useEffect } from "react";
import { getAllPostsFromServer } from "./utilities";
import Post from "./Post";
import Header from "../components/header";

const AllPosts = () => {
  const [posts, setPosts] = useState([]);
  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      const postsFromServer = await getAllPostsFromServer();
      setPosts(postsFromServer);
    }
    return () => (mounted = false);
  }, []);

  return (
    <div className="all-posts">
      {/* contact classes for blue header lol */}
      <div className="contact">
        <div className="contact-section_intro">
          <Header logoColor="blue" />
        </div>
      </div>
      <h1>Getting all the Posts from CMS</h1>
      {posts && (
        <div className="posts-padding grid grid-cols-2 gap-5">
          {posts.map((post, id) => {
            return (
              <div
                key={id}
                style={{ marginBottom: "50px", borderTop: "2px solid gold" }}
              >
                <Post post={post} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AllPosts;
