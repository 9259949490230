import { useState, useEffect } from "react";
import { getAllPostsFromServer, getPostsForPage } from "./../cms/utilities";
import Post from "./../cms/Post";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/campaign-updates/campaign-updates_wrapup-bg.jpg";
import mobileWrapup from "../images/campaign-updates/campaign-updates_wrapup-mobile.jpg";

//@ts-ignore
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";

const CampaignUpdates = () => {
  let isDesktop = window.innerWidth >= 1024;

  const [allPosts, setAllPosts] = useState<any>([]);
  const [currentPagePosts, setCurrentPagePosts] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [endOfResults, setEndOfResults] = useState(false);

  //@ts-ignore
  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      // ***Only ever get first 6 posts***
      // const postsFromServer: [] = await getNumberPosts(6);
      // const featured = postsFromServer.slice(0, 3);
      // const additional = postsFromServer.slice(3, 6);
      // setFeaturedPosts(featured);
      // setAdditionalPosts(additional);

      // ***Grid with all posts***
      // const allPostsFromServer = await getAllPostsFromServer();
      // const allPostsFromServer = await getPostsForPage(6, nextPage);
      // setAllPosts(allPostsFromServer);
      // setNextPage(nextPage + 1);

      // ***Paginate with 6 posts at a time***
      await getNextPagePosts();
    }
    return () => (mounted = false);
  }, []);

  const getNextPagePosts = async () => {
    if (!endOfResults) {
      const nextPagePosts = await getPostsForPage(6, nextPage);
      if (nextPagePosts === "rest_post_invalid_page_number") {
        setEndOfResults(true);
        return;
      }
      const newAll = allPosts;
      nextPagePosts.map((post: any) => {
        newAll.push(post);
      });
      setAllPosts(newAll);
      setNextPage(nextPage + 1);
    }
  };

  return (
    <div className="container campaign-updates">
      {!isDesktop && <Header logoColor="blue" />}
      <PageSection className="campaign-updates-section_intro">
        {isDesktop && <Header logoColor="blue" />}
        <Fade bottom>
          <h1 className="heading">Campaign Updates</h1>
        </Fade>
        <div className="list-of-posts">
          {allPosts && (
            <div className="featured-posts">
              {allPosts.map((post: any, id: any) => {
                return (
                  <div key={id} className="list-item">
                    <Post post={post} type="featured" />
                  </div>
                );
              })}
            </div>
          )}
          <div className="load-more-container">
            {endOfResults ? (
              <p className="end-of-results">More updates coming soon</p>
            ) : (
              <button
                className="cta-button load-more"
                onClick={getNextPagePosts}
              >
                Load More Posts
              </button>
            )}
          </div>
        </div>
      </PageSection>
      {/* <PageSection className="campaign-updates-section_additional-posts">
        <div className="uneven-columns">
          <div className="left">
            <p>Additional Updates</p>
          </div>
          <div className="right">
            {additionalPosts && (
              <div
                className={isDesktop ? "additional-posts" : "featured-posts"}
              >
                {additionalPosts.map((post, id) => {
                  return (
                    <div key={id} className="list-item">
                      <Post
                        post={post}
                        type={isDesktop ? "additional" : "featured"}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </PageSection> */}
      <PageSection className="campaign-updates-section_wrapup">
        <div
          className="wrapup-bg"
          style={
            isDesktop
              ? { backgroundImage: `url(${wrapupBg})` }
              : { backgroundImage: `url(${mobileWrapup})` }
          }
        >
          <Fade bottom>
            <p className="title">True to You</p>
            <p>
              Join us in strengthening the {!isDesktop && <br />}Hoban
              experience.
            </p>
            <Fade bottom>
              <Link to="/donate" className="cta-button centered margin-bottom">
                <span>Donate Now</span>
                <img src={rightArrow} />
              </Link>
            </Fade>
          </Fade>
        </div>
      </PageSection>
    </div>
  );
};

export default CampaignUpdates;
